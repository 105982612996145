<template>
  <CheckNormalGoodsMobile
    v-if="isMobile()"
    :item="item"
    @cancel="cancel"
    @refreshData="refreshData"
  />
  <CheckNormalGoodsDesktop
    v-else
    :item="item"
    @cancel="cancel"
    @refreshData="refreshData"
  />
</template>

<script>
import { isMobile } from "@/libs/helpers";

export default {
  name: "CheckNormalGoods",
  components: {
    CheckNormalGoodsMobile: () =>
      import("@/components/goods_receipt/CheckNormalGoodsMobile"),
    CheckNormalGoodsDesktop: () =>
      import("@/components/goods_receipt/CheckNormalGoodsDesktop"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isMobile,
    cancel() {
      this.$emit("cancel");
    },
    refreshData(type) {
      this.$emit("refreshData", type);
    },
  },
};
</script>

<style scoped></style>
